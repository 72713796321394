import './App.scss';
import FoodMap from './components/FoodMap';

function App() {
  return (
    <div className="App">
      <header className="app-head">
        <img src="img/plate-fork1.png" className="app-logo" alt="Taistly" />
        <span class="head-title">Taistly</span>
      </header>
      <FoodMap />
    </div>
  );
}

export default App;
